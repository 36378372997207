import * as React from 'react';
import { render } from 'react-dom';
import { enableSentry } from '@websky/core/src/utils';
import { withConfig } from '@websky/core/src/config-hoc';
import merge from 'lodash.merge';
enableSentry();
import Account from './Account';
import { config as defaultConfig } from '../../config';
export var init = function (mount, config) {
    var Component = withConfig(Account, merge({}, defaultConfig, config));
    render(React.createElement(Component, null), mount);
};
