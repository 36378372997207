import * as React from 'react';
import { HashRouter as Router, Redirect, Route } from 'react-router-dom';
import { Account } from '@websky/core';
import { ThemeProvider } from '@websky/core/src/theme';
import { RenderersProvider } from '@websky/core/src/renderProps';
import accountTheme from '../../theme/accountTheme';
import '../../theme/variables.css';
import Head from '@websky/core/src/Head';
import { renders } from '../../renders';
var AccountComponent = function () {
    return (React.createElement(Router, null,
        React.createElement(ThemeProvider, { value: accountTheme },
            React.createElement(RenderersProvider, { value: renders },
                React.createElement(Head, null),
                React.createElement(Route, { exact: true, path: "/", render: function () { return React.createElement(Redirect, { to: "/account" }); } }),
                React.createElement(Account, null)))));
};
export default AccountComponent;
