import cn from 'classnames';
import Shared from './components/Shared';
import UserCard from './components/UserCard/UserCard.css';
import AccountModule from './components/AccountModule/AccountModule.css';
import Sidebar from './components/Sidebar/Sidebar.css';
import OrderCard from './components/OrderCard/OrderCard.css';
import Traveller from './components/Traveller/Traveller.css';
import Account from './components/Account/Account.css';
import Link from './components/Link/Link.css';
import AddBooking from './components/AddBooking/AddBooking.css';
import LoginPage from './components/LoginPage/LoginPage.css';
import Loyalty from './components/Loyalty/Loyalty.css';
const theme = {
    Input: {
        Input: {
            input: Shared.Inputs.input,
            input_filled: Shared.Inputs.input_filled,
            real_input: Shared.Inputs.real_input,
            label_error: Shared.Inputs.label_error,
            textField__root: Shared.Inputs.textField__root,
            textField_focused: Shared.Inputs.textField_focused,
            input_focused: Shared.Inputs.input_focused,
            input_error: Shared.Inputs.input_error,
            input_standard: Shared.Inputs.input_standard,
            hint: Shared.Inputs.hint
        }
    },
    UserCard: {
        UserCard: {
            header_content: UserCard.header_content,
            empty: UserCard.empty,
            avatar: UserCard.avatar,
            edit: UserCard.edit,
            info: UserCard.info,
            name: UserCard.name
        }
    },
    AccountModule: {
        Account: {
            orders__wrapper: AccountModule.orders__wrapper,
            route__title: AccountModule.route__title,
            active__route: AccountModule.active__route,
            homeUserCard: AccountModule.homeUserCard,
            profileControls: AccountModule.profileControls,
            container_mailSettings: AccountModule.container_mailSettings,
            loginWrapper: AccountModule.loginWrapper
        }
    },
    Sidebar: {
        Sidebar: {
            sidebar: Sidebar.sidebar,
            sidebar__item: Sidebar.sidebar__item,
            title: Sidebar.sidebar__title,
            icon: Sidebar.sidebar__icon
        },
        MobileMenu: {
            title: Sidebar.mobileMenu__title
        }
    },
    OrderCard: {
        OrderBooking: {
            root: OrderCard.root,
            type_account: OrderCard.type_account
        },
        OrderCard: {
            buttons: OrderCard.buttons
        },
        DeleteOrderModal: {
            delete_order: OrderCard.delete_order
        },
        PaymentStatus: {
            payload: OrderCard.payload
        },
        OrderPreview: {
            inner: OrderCard.inner,
            city: OrderCard.city,
            main_city: OrderCard.main_city,
            period: OrderCard.period
        }
    },
    Traveller: {
        Traveller: {
            wrapper: Traveller.wrapper,
            header: Traveller.header,
            popup__header: Traveller.popup__header
        }
    },
    Account: {
        Account: {
            account__deleteModal: Shared.Modal.modal
        },
        InfoField: {
            field__name: Account.field__name,
            field__change: Account.field__change
        },
        DeleteAccountDialog: {
            closeButton: cn(Shared.Button.closeIcon, Shared.Button.close__popup, Shared.Modal.closeIcon, Account.deleteAccount__close)
        }
    },
    Link: {
        Link: {
            link: Link.link
        }
    },
    Passenger: {
        Switch: {
            container: Shared.Switch.container,
            element: Shared.Switch.element,
            element_active: Shared.Switch.element_active,
            element_error: Shared.Switch.element_error,
            error: Shared.Switch.error
        },
        Select: {
            error: Shared.Inputs.select_error,
            input: Shared.Inputs.select_input
        }
    },
    AddBooking: {
        AddBooking: {
            close: cn(Shared.Button.closeIcon, Shared.Button.close__popup, Shared.Modal.closeIcon, AddBooking.close),
            spoilerDetails: AddBooking.spoilerDetails
        }
    },
    TicketExample: {
        TicketExample: {
            ticketExample: cn(AddBooking.ticketExample)
        }
    },
    Button: {
        ActionButton: {
            button: Shared.Button.CTN
        }
    },
    Contacts: {
        ResendStyles: {
            back: Account.resend__back,
            timer: Account.resend_timer
        }
    },
    Modal: {
        Modal: {
            modal_rounded: Shared.Modal.modal_rounded,
            closeIcon: cn(Shared.Button.closeIcon, Shared.Button.close__popup, Shared.Modal.closeIcon),
            modal: Shared.Modal.modal,
            mobile: Shared.Modal.modal,
            closeIcon_mobileFullScreen: Shared.Button.close__fullscreen
        }
    },
    Warnings: {
        WarningsStyles: {
            warning__text: Account.warning__text
        }
    },
    Loyalty: {
        LoyaltyConnect: {
            buttons__wrapper: Loyalty.buttons__wrapper,
            group: Loyalty.group,
            group__field: cn(Loyalty.group__field, Shared.Inputs.loyalty_group__field),
            header: Loyalty.loyaltyConnect_header
        }
    },
    LoginPage: {
        RegistrationForm: {
            checkbox: LoginPage.registrationForm_checkbox,
            checked: LoginPage.registrationForm_checkbox_checked
        }
    },
    LoyaltyInfo: {
        FfpInfo: {
            button: Loyalty.ffpInfo_button
        }
    }
};
export default theme;
